import React, { useState } from 'react'
import Latestpublished from './Latestpublished';
import Articleinpress from './Articleinpress';
import Topcited from './Topcited';
import Mostdownloaded from "./Mostdownloaded"
import Mostpopular from './Mostpopular';

// props 
// LatestpublishedData
// ArticleInPressData
// TopCited

const Imgvisionsec5 = (props) => {
    const LatestPublishedData = props.LatestPublishedData
    const ArticleInPressData = props.ArticleInPressData
    const TopCitedData = props.TopCitedData
    const MostDownloadedData = props.MostDownloadedData
    const MostPopularData = props.MostPopularData

    const [block,setblock] = useState(1);

    function changeBlock1 (){
        setblock(1);
    }
    function changeBlock2 (){
        setblock(2);
    }
    function changeBlock3 (){
        setblock(3);
    }
    function changeBlock4 (){
        setblock(4);
    }
    function changeBlock5 (){
        setblock(5);
    }
    

  return (
    <div className='Imgvisionsec5 h-full w-full'>
        <div className="Imgvisionsec5Container h-full w-4/5 mx-auto flex flex-col py-8 max-lg:w-11/12">
            <div className="Imgvisionsec5Head flex flex-col">
                <div className='h-2 w-8 bg-slate-900'></div>
                <h1 className='text-3xl text-slate-900 font-semibold'>Articles</h1>
            </div>

            <div className="Imgvisionsec5Button h-full w-full border-b-2 border-slate-900 mt-4">
                <button onClick={changeBlock1} className={ block === 1 ? "py-2 px-4 text-2xl border-b-4 hover:border-orange transition-all border-slate-900" : 
                "py-2 px-4 text-2xl border-b-4 hover:border-orange transition-all"}>Latest published</button>
                <button onClick={changeBlock2} className={block === 2 ? "py-2 px-4 text-2xl border-b-4 hover:border-orange border-slate-900 transition-all" : 
                "py-2 px-4 text-2xl border-b-4 hover:border-orange transition-all"}>Articles in Press</button>
                <button onClick={changeBlock3} className={ block === 3 ? "py-2 px-4 text-2xl border-b-4 hover:border-orange transition-all border-slate-900" :
                "py-2 px-4 text-2xl border-b-4 hover:border-orange transition-all"}>Top cited</button>
                <button onClick={changeBlock4} className={ block === 4 ? 'py-2 px-4 text-2xl border-b-4 border-slate-900 hover:border-orange transition-all ' :
                'py-2 px-4 text-2xl border-b-4 border-transparent hover:border-orange transition-all '}>Most Downloaded</button>
                <button onClick={changeBlock5} className={ block === 5 ? 'py-2 px-4 text-2xl border-b-4 border-slate-900 hover:border-orange transition-all' :
                'py-2 px-4 text-2xl border-b-4 border-transparent hover:border-orange transition-all'}>Most Popular</button>
            </div>

            <div className="Imgvisionsec5Display h-full w-full relative flex">
                { block === 1 && <Latestpublished LatestPublishedData={LatestPublishedData} /> }
                { block === 2 && <Articleinpress ArticleInPressData={ArticleInPressData}  /> }
                { block === 3 && <Topcited TopCitedData = {TopCitedData} /> }
                { block === 4 && <Mostdownloaded MostDownloadedData={MostDownloadedData} /> }
                { block === 5 && <Mostpopular MostPopularData={MostPopularData} />}
            </div>
        </div>
    </div>
  )
}

export default Imgvisionsec5;