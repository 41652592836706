import React from 'react'
import pdf1 from "../../assests/Aiopen/Enhancing neural network classification using fractional-order activation functions.pdf"
import pdf2 from "../../assests/Aiopen/CPT-Colorful Prompt Tuning for pre-trained vision-language models.pdf"
const Aiopensec9 = [
    {
        id: 1,
        researchType: "Research article",
        contentType: "Abstract only",
        articleName: "Enhancing neural network classification using fractional-order activation functions",
        authorName: "Meshach Kumar, ... Giansalvo Cirrincione",
        date: "2024",
        link:pdf1,
        pdfFile:pdf1
      },
      {
        id: 2,
        researchType: "Research article",
        contentType: "Abstract only",
        articleName: "CPT: Colorful Prompt Tuning for pre-trained vision-language models",
        authorName: "Yuan Yao, ... Maosong Sun",
        date: "2024",
        link:pdf2,
        pdfFile:pdf2
      },
]

export default Aiopensec9