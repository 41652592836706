import React from 'react'
import pdf from "../../../../assests/images/sec5/pdf-icon.png"
const Articleinpresscard = (props) => {
  return (
    <div className="articleinpresscard h-full w-full flex flex-col gap-2">
        <h2 className='text-lg max-md:text-sm'>{props.researchType} <i class="ri-circle-line text-sm"></i>{props.contentType} </h2>
        <a href={props.link} className='text-4xl font-medium leading-[1.1] hover:underline max-md:text-2xl'>{props.articleName}</a>
        <h3 className='text-2xl max-md:text-base'>{props.authorName}</h3>
        <h4 className='text-xl max-md:text-sm'>{props.date}</h4>
        <a className='py-2 flex gap-2 items-center' href={props.pdfFile} target='_blank' >
          <img className='h-10 w-10' src={pdf} alt="" />
          <p className='text-xl border-b-2 border-transparent hover:border-orange'>View Pdf</p>
        </a>
    </div>
  )
}

export default Articleinpresscard;