import React from 'react'

const Imgvisionsec8 = () => {
  return (
    <div className='Imgvisionsec8 h-full w-full py-14'>
        <div className="Imgvisionsec8Container h-full w-4/5 mx-auto border grid grid-cols-4 max-lg:w-11/12">
            <div>
                
            </div>
            <div className='flex flex-col gap-2 items-center justify-center col-span-2 py-16 max-md:py-8 max-md:text-center max-md:col-span-4'>
                <p className='text-4xl max-md:text-xl'>More opportunities to publish your research:</p>
                <a className='text-4xl text-blue-600 font-semibold border-b-4 border-transparent hover:border-orange max-md:text-xl' 
                href="https://www.sciencedirect.com/browse/calls-for-papers">Browse open Calls for Papersbeta</a>
            </div>
        </div>
    </div>
  )
}

export default Imgvisionsec8