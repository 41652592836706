import React from 'react'
import Topcitedcard from './Topcited/Topcitedcard'

const Topcited = (props) => {
  const TopCitedData = props.TopCitedData
  return (
    <div className='Topcited h-full w-full py-8 border-b grid grid-cols-4 gap-4 max-md:grid-cols-1 max-lg:grid-cols-2'>
        {
          TopCitedData.map(item => 
          <Topcitedcard 
          key={item.id} 
          researchType={item.researchType} 
          contentType={item.contentType} 
          link={item.link} 
          articleName={item.articleName} 
          authorName={item.authorName} 
          date={item.date} 
          pdfFile={item.pdfFile}
          />
          )
        }
    </div>
  )
}

export default Topcited