import React from 'react'
import Articlesec1 from "../Components/Article/Articlesec1/Articlesec1"
import Articlesec2 from "../Components/Article/Articlesec2/Articlesec2"
import Articlesec3 from "../Components/Article/Articlesec3/Articlesec3"
import Articlesec4 from "../Components/Article/Articlesec4/Articlesec4"
import Articlesec5 from "../Components/Article/Articlesec5/Articlesec5"
import Articlesec6 from "../Components/Article/Articlesec6/Articlesec6"
import Articlesec7 from "../Components/Article/Articlesec7/Articlesec7"
import Articlesec8 from "../Components/Article/Articlesec8/Articlesec8"
import Articlesec9 from "../Components/Article/Articlesec9/Articlesec9"
import Articlesec10 from "../Components/Article/Articlesec10/Articlesec10"

import articleCoverImg from "../assests/images/Home/herosection/journel-1.jpg"
import authorImg from "../assests/images/ImageVision/author-2.jpg"

import Aiopenlatestpublished from "../Components/Aiopen/Aiopenlatestpublished"
import Aiopenarticleinpress from '../Components/Aiopen/Aiopenarticleinpress'
import Aiopentopcited from '../Components/Aiopen/Aiopentopcited'
import Aiopenmostdownloaded from '../Components/Aiopen/Aiopenmostdownloaded'
import Aiopenmostpopular from "../Components/Aiopen/Aiopenmostpopular"
import Aiopensec7 from '../Components/Aiopen/Aiopensec7'
import Aiopensec9 from '../Components/Aiopen/Aiopensec9'
const Aiopen = () => {
  const AuthorData = [{
    id:1,
    authorImg:authorImg,
    authorName:"Jie Tang",
    authorDesignation:"Tsinghua University, China"
  }]

  const newsData =[
    {
      id:1,
      date:"22 February 2023",
      link:"https://www.sciencedirect.com/journal/ai-open/vol/5/suppl/C",
      heading:"Guidelines for submitting proposals for journal Special Issues - AI Open"
    }
  ]
  
  const announcementData =[
    {
      id:1,
      date:"26 September 2021",
      link:"https://www.sciencedirect.com/journal/image-and-vision-computing/about/announcements#in-support-of-equality-inclusion-diversity-image-and-vision-computing",
      heading:"In support of equality, inclusion & diversity - AI Open"
    }
  ]

  const callforpaperData =[
    {
      id:1,
      date:"30 June 2025",
      link:"https://www.sciencedirect.com/special-issue/315445/special-issue-on-ai-ethics-governance-and-risk-management-ai-open",
      heading:"Special Issue on AI Ethics, Governance and Risk Management - AI Open"
    },
  ]
  return (
    <div>

        <Articlesec1 
        articleCover={articleCoverImg}
        articleName="AI Open" 
        siteScore="45.0" 
        impactFactor="3.4" />

        <Articlesec2 
        content="AI Open is a freely accessible platform to share actionable knowledge and forward-thinking perspectives on the theory of artificial intelligence and its applications. The journal welcomes research articles, review papers, perspectives, short communications and technical notes on all aspects of …" />

        <Articlesec3 
            articlePublishRate="900"
            firstDecision="23"
            reviewDate="37"
            submissionAcceptance ="53"
            buttonLink="https://elsevierpublications.org/aiopen"
        />

        <Articlesec4 
        link="https://www.sciencedirect.com/journal/ai-open/about/editorial-board"
        AuthorData={AuthorData} />

        <Articlesec5
        LatestPublishedData= {Aiopenlatestpublished}
        ArticleInPressData= {Aiopenarticleinpress}
        TopCitedData={Aiopentopcited}
        MostDownloadedData={Aiopenmostdownloaded} 
        MostPopularData={Aiopenmostpopular}
        />

        <Articlesec6 
          newsData={newsData}
          announcementData={announcementData}
          callforpaperData={callforpaperData}
          link="https://www.sciencedirect.com/journal/ai-open/about/editorial-board"
        />

        <Articlesec7 articleSec7CardData={Aiopensec7} />     

        <Articlesec8 />   

        <Articlesec9 articleSec9Data={Aiopensec9} />

        <Articlesec10 />
    </div>
  )
}

export default Aiopen;