import React from 'react'
import pdf1 from "../../assests/Aiopen/pdf-1.pdf"

const Aiopenlatestpublished = [{
    id: 1,
    researchType: "Research article",
    contentType: "Abstract only",
    articleName: "Advanced end to end system for new age farmers using machine learning",
    authorName: "S. Rathana Sabapathy,S. Rathana Sabapathy, B. Madhavan, N. Yokesh, K.R. Muralikrishnan",
    date: "July 2024",
    link:pdf1,
    pdfFile:pdf1
},{
    id: 2,
    researchType: "Research article",
    contentType: "Abstract only",
    articleName: "Natural language processing in medical records enhancing decision making and patient outcomes",
    authorName: "Md Tahmeed Abdullah, ... Md Fokhrul Islam",
    date: "July 2024",
    link:pdf1,
    pdfFile:pdf1
},{
    id: 3,
    researchType: "Research article",
    contentType: "Abstract only",
    articleName: "Nlp driven chatbots for mental health a sentiment analysis based approach to remote care",
    authorName: "Md Tahmeed Abdullah, ... Md Fokhrul Islam",
    date: "July 2024",
    link:pdf1,
    pdfFile:pdf1
},
]

export default Aiopenlatestpublished