import React from 'react'
import Articlebutton from '../../Articlebutton/Articlebutton'

const Imgvisionsec7 = (props) => {
    const articleSec7CardData = props.articleSec7CardData
    // const Imgvisionsec7CardData = [
    //     {
    //         id:1,
    //         title:"Embodied Artificial Intelligence for Robotic Vision and Navigation",
    //         link:"#",
    //         author: "Xiao Bai, Haonan Luo, Zechao Li, Joey Tianyi Zhou",
    //         content : "Embodied Artificial Intelligence has emerged as an essential area for research and applications. Unlike traditional AI systems that operate solely in the digital realm, Embodied AI seeks to imbue machines with a physical presence, enabling them to …",
    //         date: "30 April 2025"

    //     },
    //     {
    //         id:2,
    //         title:"Advances in Computer Vision Theory and Applications",
    //         link:"#",
    //         author: "Petia Radeva, Antonino Furnari - Submission deadline: 10 July 2024",
    //         content : "The last decade has seen a revolution in the theory and application of Artificial Intelligence and Machine Learning in Computer Vision. In this conference edition, we look at advances in Computer Vision based on Machine Learning methods.",
    //         date: "10 July 2024"

    //     },
    //     {
    //         id:3,
    //         title:"Recent Advances in Computer Vision for Assisted Living",
    //         link:"#",
    //         author: "Imad Rida, Francisco Florez-Revuelta",
    //         content : "The percentage of persons who require healthcare support and a secure environment is unquestionably rising in our aging culture. As a result, there is an increasing need for technological solutions for prevention, diagnosis, and rehabilitation. …",
    //         date: "15 August 2024"

    //     }
    // ]
  
    return (
    <div className='Imgvisionsec7 h-full w-full py-10 bg-[#ededed]'>
        <div className="Imgvisionsec7Container h-full w-4/5 mx-auto max-lg:w-11/12">
            
            <div className="Imgvisionsec7Head">
                <div className='h-2 w-12 bg-slate-900 my-1 max-md:h-1'></div>
                <h1 className='text-3xl pb-8 font-semibold tracking-normal max-md:text-2xl'>Calls for papers</h1>
            </div>

            <div className="Imgvisionsec7List grid grid-cols-3 gap-6 max-md:grid-cols-1 max-lg:grid-cols-2 max-xl:grid-cols-2">
                {
                    articleSec7CardData.map((item) => 
                        <div className="Imgvisionsec7Card h-full w-full p-8 bg-slate-100 flex flex-col gap-2">
                            <a className='text-4xl font-semibold hover:underline max-md:text-3xl' href={item.link}>{item.title}</a>
                            <h2 className='text-lg max-md:text-base'>Guest editors: {item.author}</h2>
                            <p className='text-2xl py-2 leading-[1.1] max-md:text-xl'>{item.content}</p>
                            <h3 className='text-xl max-md:text-sm'>Submission deadline: <span className='font-bold'>{item.date}</span></h3>
                        </div>
                    )
                }
            </div>
            <div className="Imgvisionsec7Button py-6 h-full w-full">
                <Articlebutton link="https://www.sciencedirect.com/journal/image-and-vision-computing/about/call-for-papers" name="View all calls for papers for special issues" />
            </div>

        </div>
    </div>
  )
}

export default Imgvisionsec7