import React from 'react'
import pdf1 from "../../assests/Aiopen/pdf-1.pdf"

const imagevisionmostpopular = [
    {
        id: 1,
        researchType: "Research article",
        contentType: "Abstract only",
        articleName: "VAE-GAN3D: Leveraging image-based semantics for 3D zero-shot recognition",
        authorName: "Md Tahmeed Abdullah, ... Md Fokhrul Islam",
        date: "July 2024",
        link:"#",
        pdfFile:pdf1
      },{
        id: 2,
        researchType: "Research article",
        contentType: "Abstract only",
        articleName: "VAE-GAN3D: Leveraging image-based semantics for 3D zero-shot recognition",
        authorName: "Md Tahmeed Abdullah, ... Md Fokhrul Islam",
        date: "July 2024",
        link:"#",
        pdfFile:pdf1
      },{
        id: 3,
        researchType: "Research article",
        contentType: "Abstract only",
        articleName: "VAE-GAN3D: Leveraging image-based semantics for 3D zero-shot recognition",
        authorName: "Md Tahmeed Abdullah, ... Md Fokhrul Islam",
        date: "July 2024",
        link:"#",
        pdfFile:pdf1
      },{
        id: 4,
        researchType: "Research article",
        contentType: "Abstract only",
        articleName: "VAE-GAN3D: Leveraging image-based semantics for 3D zero-shot recognition",
        authorName: "Md Tahmeed Abdullah, ... Md Fokhrul Islam",
        date: "July 2024",
        link:"#",
        pdfFile:pdf1
      },
]


export default imagevisionmostpopular;