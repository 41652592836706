import React from 'react'

// PROPS:
// CONTENT

const Imgvisionsec2 = (props) => {
  return (
    <div className='Imgvisionsec2 h-full w-full py-10'>
        <div className="ImgvisionAbout h-full w-4/5 mx-auto bg-[#f5f5f5] py-12 px-40 flex flex-col gap-4 
        max-sm:px-8 max-sm:py-4 
        max-md:px-10 max-lg:px-10 max-lg:w-11/12 max">
            <h1 className='text-4xl tracking-wide font-semibold max-sm:text-3xl'>About the Journal</h1>
            <p className='text-lg max-sm:text-base'>{props.content}</p>
            <button className='w-fit text-xl text-blue-500 my-4 border-b-2 border-transparent hover:border-b-2 hover:border-[#eb6500] 
            max-sm:text-lg'>View full aims & scope</button>
        </div>
    </div>
  )
}

export default Imgvisionsec2;