import React from 'react'
import Mostdownloadedcard from './Mostdownloaded/Mostdownloadedcard';

const Mostpopular = (props) => {
    const MostPopularData = props.MostPopularData
  return (
    <div className='MostPopular h-full w-full py-8 border-b grid grid-cols-4 gap-4 max-md:grid-cols-1 max-lg:grid-cols-2'>
        {
            MostPopularData.map(item => 
            <Mostdownloadedcard 
                key={item.id} 
                researchType={item.researchType} 
                contentType={item.contentType} 
                link={item.link} 
                articleName={item.articleName} 
                authorName={item.authorName} 
                date={item.date}
                pdfFile={item.pdfFile}
            />)
        }
    </div>
  )
}

export default Mostpopular;