import React from 'react'
import Articlesec1 from "../Components/Article/Articlesec1/Articlesec1"
import Articlesec2 from "../Components/Article/Articlesec2/Articlesec2"
import Articlesec3 from "../Components/Article/Articlesec3/Articlesec3"
import Articlesec4 from "../Components/Article/Articlesec4/Articlesec4"
import Articlesec5 from "../Components/Article/Articlesec5/Articlesec5"
import Articlesec6 from "../Components/Article/Articlesec6/Articlesec6"
import Articlesec7 from "../Components/Article/Articlesec7/Articlesec7"
import Articlesec8 from "../Components/Article/Articlesec8/Articlesec8"
import Articlesec9 from "../Components/Article/Articlesec9/Articlesec9"
import Articlesec10 from "../Components/Article/Articlesec10/Articlesec10"

import authorImg1 from "../assests/images/ImageVision/author-1.jpg"
import authorImg2 from "../assests/images/ImageVision/author-2.jpg"
import articlecover from "../assests/images/Home/herosection/journel-2.jpg"
import Imagevisionlatestpublished from "../Components/Imagevision/Imagevisionlatestpublished"
import Imagevisionarticleinpress from "../Components/Imagevision/Imagevisionarticleinpress"
import Imagevisiontopcited from "../Components/Imagevision/Imagevisiontopcited"
import Imagevisionmostdownloaded from "../Components/Imagevision/Imagevisionmostdownloaded"
import imagevisionmostpopular from "../Components/Imagevision/imagevisionmostpopular"
import Imagevisionsec7 from "../Components/Imagevision/Imagevisionsec7"
import Imagevisionsec9 from "../Components/Imagevision/Imagevisionsec9"


const Imagevision = () => {
  const AuthorData = [{
    id:1,
    authorImg:authorImg1,
    authorName:"X. Wang, PhD",
    authorDesignation:"Huazhong University of Science and Technology, 430074, Wuhan, Hubei, China"
  },{
    id:2,
    authorImg:authorImg2,
    authorName:"M. Nappi, PhD",
    authorDesignation:"University of Salerno, 84084, Fisciano, Italy"
  }
]

  const newsData =[
    {
      id:1,
      date:"22 February 2023",
      link:"https://www.sciencedirect.com/journal/image-and-vision-computing/about/news#guidelines-for-submitting-proposals-for-journal-special-issues-image-and-vision-computing",
      heading:"Guidelines for submitting proposals for journal Special Issues - Image and Vision Computing"
    }
  ]
  
  const announcementData =[
    {
      id:1,
      date:"26 September 2021",
      link:"https://www.sciencedirect.com/journal/image-and-vision-computing/about/announcements#in-support-of-equality-inclusion-diversity-image-and-vision-computing",
      heading:"In support of equality, inclusion & diversity - Image and Vision Computing"
    }
  ]

  const callforpaperData =[
    {
      id:1,
      date:"17 April 2024",
      link:"https://www.sciencedirect.com/journal/image-and-vision-computing/about/call-for-papers#embodied-artificial-intelligence-for-robotic-vision-and-navigation",
      heading:"Embodied Artificial Intelligence for Robotic Vision and Navigation"
    },
    {
      id:2,
      date:"30 January 2024",
      link:"https://www.sciencedirect.com/journal/image-and-vision-computing/about/call-for-papers#advances-in-computer-vision-theory-and-applications",
      heading:"Advances in Computer Vision Theory and Applications"
    },
    {
      id:1,
      date:"24 September 2023",
      link:"https://www.sciencedirect.com/journal/image-and-vision-computing/about/call-for-papers#recent-advances-in-computer-vision-for-assisted-living",
      heading:"Recent Advances in Computer Vision for Assisted Living"
    },
  ]
  return (
    <div className='ImageVision'>
        <Articlesec1 
        articleCover={articlecover}
        articleName="Image and Vision Computing" 
        siteScore="8.5" 
        impactFactor="4.2"
        />

        <Articlesec2 
          content="Image and Vision Computing has as a primary aim the provision of an effective medium of interchange for the results of high quality theoretical and applied research fundamental to all aspects of image interpretation and computer vision. The journal publishes work that proposes new image …"
        />

        <Articlesec3 
            articlePublishRate="1090"
            firstDecision="4"
            reviewDate="97"
            submissionAcceptance ="104"
            buttonLink="https://www.sciencedirect.com/journal/image-and-vision-computing/about/insights"
        />

        <Articlesec4 
          link="https://www.sciencedirect.com/journal/image-and-vision-computing/about/editorial-board"
          AuthorData={AuthorData}
        />

        <Articlesec5 
          LatestPublishedData= {Imagevisionlatestpublished}
          ArticleInPressData= {Imagevisionarticleinpress}
          TopCitedData={Imagevisiontopcited}
          MostDownloadedData={Imagevisionmostdownloaded} 
          MostPopularData={imagevisionmostpopular}
        />

        <Articlesec6
          newsData={newsData}
          announcementData={announcementData}
          callforpaperData={callforpaperData}
          link="https://www.sciencedirect.com/journal/image-and-vision-computing/about/news" 

          />

        <Articlesec7 articleSec7CardData={Imagevisionsec7} />

        <Articlesec8 />

        <Articlesec9 articleSec9Data={Imagevisionsec9}/>

        <Articlesec10 />
    </div>
  )
}

export default Imagevision;