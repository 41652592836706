import React from 'react'
import Articlebutton from '../../Articlebutton/Articlebutton';

const Imgvisionsec9 = (props) => {
    const articleSec9Data = props.articleSec9Data
//     const Imgvisionsec9CardData =[{
//         id:1,
//         title:"Recent Advances in Computer Vision for Assisted Living",
//         editor: "Dr. Imad Rida, Professor Francisco Florez-Revuelta",
//         date: "19 March 2024",
//         link:"#"
//     },{
//         id:2,
//         title:"Human-oriented 3D vision in the Metaverse",
//         editor: "Xin Ning, Prayag Tiwari, Xiao Bai, Lusi Li, Xiaojie Guo",
//         date: "14 December 2023",
//         link:"#"
//     },
//     {
//         id:3,
//         title:"AI on Digital Health: Computer vision applications in medical imaging",
//         editor: "Paola Barra, Alessia Auriemma Citarella, Thierry BOUWMANS, Giuseppe Placidi",
//         date: "7 November 2023",
//         link:"#"
//     },{
//         id:4,
//         title:"Synthetic Data in Generalizable Video Analytics",
//         editor: "Xun Yang, Liang Zheng, Piotr Koniusz, Milind Naphade, Meng Wang",
//         date: "5 April 2023",
//         link:"#"
//     }
// ]
  return (
    <div className='Imgvisionsec9 h-full w-full py-8 bg-[#f5f5f5]'>
        <div className="Imgvisionsec9Container h-full w-4/5 mx-auto max-lg:w-11/12">
            <div className="Imgvisionsec9Head">
                <div className='h-2 w-12 bg-slate-900 my-1 max-md:h-1'></div>
                <h1 className='text-3xl pb-8 font-semibold tracking-normal max-md:text-2xl max-md:pb-4'>Special issues and article collections</h1>
            </div>

            <div className="Imgvisionsec9Block h-full w-full py-6 grid gap-6 grid-cols-4 max-md:grid-cols-1 max-lg:grid-cols-2">
                {
                    articleSec9Data.map((item)=>
                        <div className="Imgvisionsec9Card h-full w-full bg-white p-8 flex flex-col gap-4">
                            <a className='text-2xl font-medium text-blue-700 border-b-4 border-transparent hover:underline' href={item.link}>{item.title}</a>
                            <h2 className='text-xl'>Edited by {item.editor}</h2>
                            <p>{item.date}</p>
                        </div>
                    )
                }
            
            </div>
            
            <div className="Imgvisionsec9Button py-6 flex gap-10 max-md:flex-col">
                <Articlebutton name="View all special issues and article collections" link="https://www.sciencedirect.com/journal/image-and-vision-computing/special-issues" />
                <Articlebutton name="View all issues" link="https://www.sciencedirect.com/journal/image-and-vision-computing/issues" />
            </div>
        </div>
    </div>
    
  )
}

export default Imgvisionsec9;