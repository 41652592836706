import React from 'react'
import pdf1 from "../../assests/Aiopen/pdf-1.pdf"
import lifiPdf from "../../assests/Imagevision/pdf/Lifi_based_vehicle_to_vehicle_communication.pdf"
import predictPdf from "../../assests/Imagevision/pdf/Predicting_the_Drug_Rating_Based_on_Reviews_by_Using_Natural_Language_Processing.pdf"
import cropPdf from "../../assests/Imagevision/pdf/Crop_recommendation_fertilizer_suggestion_and_plant_diseases_detection_approach.pdf"
import ancientPdf from "../../assests/Imagevision/pdf/Ancient_Tamil_Character_Recognition_From_Stone_Inscription_Using_Optical_Character_Recognition.pdf"
import adaptivePdf from '../../assests/Imagevision/pdf/Adaptive_Secure_Nearest_Neighbor_Query_Processing_over_Encrypted_Data.pdf'
import vertebratePdf from "../../assests/Imagevision/pdf/Vertebrae_Landmark_Detection_And_Scoliosis_Assessment_Using_Deep_Learning.pdf"
import mentalHealthPdf from "../../assests/Imagevision/pdf/Mental_Health_Assistant_Chatbot_Using_Neural_Network_Techniques.pdf"
const Imagevisionlatestpublished = [
    {
        id: 1,
        researchType: "Research article",
        contentType: "Abstract only",
        articleName: "Lifi Based Vehicle to Vehicle Communication",
        authorName: "S. Rathana Sabapathy,S. Rathana Sabapathy, B. Madhavan, N. Yokesh, K.R. Muralikrishnan",
        date: "November 2024",
        link:lifiPdf,
        pdfFile:lifiPdf
    },{
        id: 2,
        researchType: "Research article",
        contentType: "Abstract only",
        articleName: "Predicting the Drug Rating Based on Reviews by Using Natural Language Processing",
        authorName: "Uma S, Surya M.M, Surya P.M, Venkat M",
        date: "November 2024",
        link:predictPdf,
        pdfFile:predictPdf
    },
    {
        id: 3,
        researchType: "Research article",
        contentType: "Abstract only",
        articleName: "Crop Recommendation with Fertilizer Suggestion and Plant Disease Detection",
        authorName: "M. Sumithra, B. Buvaneswari, R. Manikandan, K. Madhu Sudharshanan, R. Karthik Padmaraj",
        date: "November 2024",
        link:cropPdf,
        pdfFile:cropPdf
    },
    {
        id: 4,
        researchType: "Research article",
        contentType: "Abstract only",
        articleName: "Ancient Tamil Character Recognition From Stone Inscription Using Optical Character Recognition",
        authorName: "D. Karunkuzhali, R. Bhuvaneswaran, D. Narendra babu, K. Balaji",
        date: "November 2024",
        link:ancientPdf,
        pdfFile:ancientPdf
    },
    {
        id: 5,
        researchType: "Research article",
        contentType: "Abstract only",
        articleName: "Adaptive Secure Nearest Neighbor Query Processing over Encrypted Data",
        authorName: "B. Karthikeyan, M. Abishekpriyan, J.S. Baejoe, S. Harish Kannan",
        date: "November 2024",
        link:adaptivePdf,
        pdfFile:adaptivePdf
    },
    {
        id: 6,
        researchType: "Research article",
        contentType: "Abstract only",
        articleName: "Mental Health Assistant Chatbot Using Neural Network Techniques",
        authorName: "Balaji A, Akash R, Kushal Balakrishna, Mukesh S",
        date: "November 2024",
        link:mentalHealthPdf,
        pdfFile:mentalHealthPdf
    },
    {
        id: 7,
        researchType: "Research article",
        contentType: "Abstract only",
        articleName: "Vertebrae Landmark Detection And Scoliosis Assessment Using Deep Learning",
        authorName: "Meera S, Sharmika Sree R, Mokshika T, Srilakshmi P",
        date: "November 2024",
        link:vertebratePdf,
        pdfFile:vertebratePdf
    },
]

export default Imagevisionlatestpublished;