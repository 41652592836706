import React from 'react'
import pdf1 from "../../assests/Aiopen/Enhancing neural network classification using fractional-order activation functions.pdf"

const Aiopenarticleinpress = [
    {
        id: 1,
        researchType: "Research article",
        contentType: "Abstract only",
        articleName: "Enhancing neural network classification using fractional-order activation functions",
        authorName: "Meshach Kumar, ... Giansalvo Cirrincione",
        date: "2024",
        link:pdf1,
        pdfFile:pdf1
    }
]

export default Aiopenarticleinpress