import React from 'react'
import "./Articlebutton.css"

const Articlebutton = (props) => {
  return (
    <a className='Articlebutton h-full w-fit flex items-center gap-4' href={props.link}>
         <div className='ArticlebuttonBox h-ful w-fit text-white p-2 bg-blue-600 border-2 border-transparent'>
          <i class="ri-arrow-right-s-line text-3xl "></i>
        </div>
        <p className='ArticlebuttonName text-2xl border-b-2 border-transparent'>{props.name}</p>
    </a>
  )
}

export default Articlebutton