import React from 'react'

const News = (props) => {
    const newsData = props.data
  return (
    <div>
        {
            newsData.map(data => 
            <div className="Imgvisionsec6Block h-full w-full py-6 flex flex-col gap-2">
            <h2 className='text-xl text-slate-600 max-md:text-sm'>{data.date}</h2>
            <a href={data.link} 
            className='text-2xl font-bold hover:border-b-2 hover:border-orange w-fit max-md:text-lg'>{data.heading}</a>
            </div>
            )
        }
    </div>
  )
}

export default News;