import React from 'react'
// import Latestpublsheddata from './Latestpublished/Latestpublsheddata';
import Latestpublishedcard from './Latestpublished/Latestpublishedcard';

const Latestpublished = (props) => {
  const LatestPublishedData = props.LatestPublishedData
  return (
    <div className='Latestpublished h-full w-full py-8 border-b grid grid-cols-4 gap-4 max-md:grid-cols-1 max-lg:grid-cols-2'>
    {
      LatestPublishedData.map(item =>
        <Latestpublishedcard 
        key={item.id} 
        researchType={item.researchType} 
        contentType={item.contentType} 
        link={item.link} 
        articleName={item.articleName} 
        authorName={item.authorName} 
        date={item.date} 
        pdfFile={item.pdfFile}
        />
        
      )
    }
    </div>
  )
}

export default Latestpublished;

{/* <div className="LatestpublishedCard h-full w-full flex flex-col gap-2">
    <h2 className='text-lg'>{props.researchType} <i class="ri-circle-line text-sm"></i>{props.contentType} </h2>
    <a href={props.link} className='text-4xl font-medium leading-[1.1] hover:underline'>{props.articleName}</a>
    <h3 className='text-2xl'>{props.authorName}</h3>
    <h4 className='text-xl'>{props.date}</h4>
</div> */}