import React from 'react'
// import articleInIressData from './Articleinpress/Articleinpressdata';
import Articleinpresscard from './Articleinpress/Articleinpresscard';


const Articleinpress = (props) => {
  const ArticleInPressData = props.ArticleInPressData
  return (
    <div className='articleInPess h-full w-full py-8 border-b grid grid-cols-4 gap-4 max-md:grid-cols-1 max-lg:grid-cols-2'>
      {
        ArticleInPressData.map(item =>
          <Articleinpresscard 
          key={item.id} 
          researchType={item.researchType} 
          contentType={item.contentType} 
          link={item.link} 
          articleName={item.articleName} 
          authorName={item.authorName} 
          date={item.date} 
          pdfFile={item.pdfFile}
          />
        )
      }
    </div>
  )
}

export default Articleinpress;