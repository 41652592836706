import React from 'react'
import './Herosection.css'
import icon1 from "../../../assests/images/Home/icon/icon-publish.svg"
import icon2 from "../../../assests/images/Home/icon/icon-find-journal.svg"
import icon3 from "../../../assests/images/Home/icon/icon-discover-products.svg"
import icon4 from "../../../assests/images/Home/icon/icon-shop-books.svg"
import icon5 from "../../../assests/images/Home/icon/icon-find-job.svg"
import Heroblogswipe from './Heroblogswipe'
import Herolink from './Herolink'
import journelone from "../../../assests/images/Home/herosection/journel-1.jpg"
import journeltwo from "../../../assests/images/Home/herosection/journel-2.jpg"
import { Link } from 'react-router-dom'

const heroSectionLink = [{
    id: 1,
    img:icon1,
    name:"Publish with Elseiver",
    link:"https://www.elsevier.com/researcher/author/publish-with-us"
},{
    id: 2,
    img: icon2,
    name:"Find a Jounal Article",
    link:"https://www.sciencedirect.com/"
},{
    id: 3,
    img: icon3,
    name:"Discover Products",
    link:"https://www.elsevier.com/products"
},{
    id: 4,
    img: icon4,
    name:"Shop books & journals",
    link:"https://shop.elsevier.com/search"
},{
    id: 5,
    img: icon5, 
    name:"Find a job",
    link:"https://www.elsevier.com/about/careers"
}
]

const Herosection = () => {
  return (
    <div className='h-[750px] w-full overflow-hidden flex m-0 p-0 max-sm:h-full'>
        {/* <div className="heroWrap absolute h-full w-full">
            <img className="h-full w-full object-cover p-0 m-0" src="//images.ctfassets.net/o78em1y1w4i4/73rJ7ExSCz3noeh3iRub06/88e18cbfb179ed1a95f7d6ee9a781bec/header-background-mobile.webp?fm=webp&amp;w=375&amp;q=25" alt="" width="375" height="832" sizes="100vw" srcset="//images.ctfassets.net/o78em1y1w4i4/73rJ7ExSCz3noeh3iRub06/88e18cbfb179ed1a95f7d6ee9a781bec/header-background-mobile.webp?fm=webp&amp;w=640&amp;q=25 640w, //images.ctfassets.net/o78em1y1w4i4/73rJ7ExSCz3noeh3iRub06/88e18cbfb179ed1a95f7d6ee9a781bec/header-background-mobile.webp?fm=webp&amp;w=750&amp;q=25 750w, //images.ctfassets.net/o78em1y1w4i4/73rJ7ExSCz3noeh3iRub06/88e18cbfb179ed1a95f7d6ee9a781bec/header-background-mobile.webp?fm=webp&amp;w=828&amp;q=25 828w, //images.ctfassets.net/o78em1y1w4i4/73rJ7ExSCz3noeh3iRub06/88e18cbfb179ed1a95f7d6ee9a781bec/header-background-mobile.webp?fm=webp&amp;w=1080&amp;q=25 1080w, //images.ctfassets.net/o78em1y1w4i4/73rJ7ExSCz3noeh3iRub06/88e18cbfb179ed1a95f7d6ee9a781bec/header-background-mobile.webp?fm=webp&amp;w=1200&amp;q=25 1200w, //images.ctfassets.net/o78em1y1w4i4/73rJ7ExSCz3noeh3iRub06/88e18cbfb179ed1a95f7d6ee9a781bec/header-background-mobile.webp?fm=webp&amp;w=1920&amp;q=25 1920w, //images.ctfassets.net/o78em1y1w4i4/73rJ7ExSCz3noeh3iRub06/88e18cbfb179ed1a95f7d6ee9a781bec/header-background-mobile.webp?fm=webp&amp;w=2048&amp;q=25 2048w, //images.ctfassets.net/o78em1y1w4i4/73rJ7ExSCz3noeh3iRub06/88e18cbfb179ed1a95f7d6ee9a781bec/header-background-mobile.webp?fm=webp&amp;w=3840&amp;q=25 3840w" decoding="async" loading="eager"></img>
        </div> */}
        <div className="heroContent h-full w-full flex flex-col items-center justify-between gap-8">

            <div className="heroMain h-full w-11/12 ml-auto flex max-sm:flex-col max-sm:ml-0">

                <div className="heroMainLeft h-full w-1/2 flex flex-col gap-4 justify-center
                s max-sm:w-full py-6">
                    <h1 className='text-7xl max-sm:text-5xl max-sm:leading-[1.1] max-sm:font-bold'>For the benefit <br /> <span className='s text-red-600'>of Society</span></h1>
                    <p className='w-4/5 text-2xl leading-[1.1] max-sm:w-11/12'>We help researchers and healthcare professionals advance science and improve healthcare outcomes.</p>
                </div>
                
                <div className="heroMainRight h-full w-1/2 flex items-center gap-2 max-sm:w-full p-4">
                    <div className="herocard relative h-[500px] w-[380px] max-sm:h-[250px] max-sm:w-[200px]">
                        <div className="heroBlogImg absolute top-0 left-0 h-full w-full overflow-hidden">
                            <Link to="/aiopen">
                                <a className='h-fit w-fit' href="https://www.sciencedirect.com/journal/ai-open">
                                    <img className='h-full w-full object-contain drop-shadow-xl' src={journelone} alt="" />
                                </a>
                            </Link>
                        </div>
                    </div>
                    <div className="herocard relative h-[500px] w-[380px] max-sm:h-[250px] max-sm:w-[200px]">
                        <div className="heroBlogImg absolute top-0 left-0 h-full w-full overflow-hidden">
                            <Link to="./imagevision">
                                <a className='h-fit w-fit' href="https://www.sciencedirect.com/journal/image-and-vision-computing">
                                    <img className='h-full w-full object-contain drop-shadow-xl' src={journeltwo} alt="" />
                                </a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="heroBottom h-fit w-4/5 mx-auto flex flex-col gap-4">
                <p className='text-xl'>I want to..</p>
                <div className="heroLinks h-full w-full rounded-2xl bg-gray-100 flex items-center justify-around p-4
                 max-sm:h-full max-sm:grid max-sm:grid-cols-1  max-sm:gap-6 max-sm:p-4">
                    {
                        heroSectionLink.map((item) =>
                            <div className="heroLink flex items-center gap-2">
                                <img className='h-14 w-14' src={item.img} alt="" />
                                <a className='cursor-pointer hover:underline text-xl max-sm:text-base' href={item.link}>{item.name}</a>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default Herosection; 