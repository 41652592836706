import React from 'react'

const Callforpaper = (props) => {
    const callForPaperData = props.callforpaperData
  return (
    <div className='h-full w-full py-6 flex gap-2 max-md:flex-col'>
        {
            callForPaperData.map(item =>      
            <div className='border-b-2 py-2'>
                <h2 className='text-xl text-slate-600 max-md:text-sm'>{item.date}</h2>
                <a href={item.link} className='text-2xl font-bold hover:border-b-2 hover:border-orange w-fit max-md:text-lg'>{item.heading}</a>
            </div>
            ) 
        }
    </div>
  )
}

export default Callforpaper;