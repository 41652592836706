import React from 'react'

const Announcement = (props) => {
    const announcementData = props.announcementData
    return (
    <div>
        {
            announcementData.map(item => 
                <div className="Imgvisionsec6Block h-full w-full py-6 flex flex-col gap-2">
                    <h2 className='text-xl text-slate-600 max-md:text-sm'>{item.date}</h2>
                    <a href={item.link} className='text-2xl font-bold hover:border-b-2 hover:border-orange w-fit max-md:text-lg'>{item.heading}</a>
                </div>
          )
        }
    </div>
  )
}

export default Announcement;