import React from 'react'
import Author from './Author';

// props:
// link
// array -
// authorImg
// authorname
// authorDesignation

const Imgvisionsec4 = (props) => {
    const AuthorData = props.AuthorData
  return (
    <div className='Imgvisionsec4 h-full w-full bg-[#505050]'>

        <div className='Imgvisionsec4__container h-full w-4/5 py-16 mx-auto max-lg:w-11/12 max-lg:py-12'>

            <div className="articlesec4Head flex text-white items-center">
                <h1 className='text-3xl font-semibold pr-4 border-r-2 border-white max-md:text-xl max-lg:text-2xl'>Editors in Cheif</h1>
                <a className='text-xl mx-4 border-b-2 border-white hover:border-orange max-md:text-sm max-lg:text-base' href={props.link}>View full editorial board</a>
            </div>

            {/* <Author AuthorData={AuthorData} /> */}
            <div className="articlesec4Author grid grid-cols-2 pt-10 pb-4 max-lg:grid-cols-1 max-lg:gap-8">
            {
                AuthorData.map( item => 
                    <div className="articlesec4AuthorCard flex items-center gap-4">
                        <div className="articlesec4AuthorLeft h-48 w-48 max-md:h-36 max-md:w-36 flex items-center justify-center">
                            <img className='rounded-full object-cover' src={item.authorImg} alt="" />
                        </div>
                        <div className="articlesec4AuthorRight h-full w-full text-white flex flex-col justify-center gap-2 px-4">
                            <h1 className='text-3xl font-semibold'>{item.authorName}</h1>
                            <p className='text-lg leading-[1.1] w-4/5'>{item.authorDesignation}</p>
                    </div>
            </div>)
            }
            </div>
        </div>
    </div>
  )
}

export default Imgvisionsec4;