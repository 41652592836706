import React from 'react'
import pdf1 from "../../assests/Aiopen/CPT-Colorful Prompt Tuning for pre-trained vision-language models.pdf"

const Aiopenmostpopular = [
    {
      id: 1,
      researchType: "Research article",
      contentType: "Abstract only",
      articleName: "CPT: Colorful Prompt Tuning for pre-trained vision-language models",
      authorName: "Yuan Yao, ... Maosong Sun",
      date: "2024",
      link:pdf1,
      pdfFile:pdf1
      }
]

export default Aiopenmostpopular;