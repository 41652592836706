import React from 'react'

const Aiopensec7 = [
    {
        id:1,
        title:"Embodied Artificial Intelligence for Robotic Vision and Navigation",
        link:"https://www.sciencedirect.com/journal/image-and-vision-computing/about/call-for-papers#embodied-artificial-intelligence-for-robotic-vision-and-navigation",
        author: "Xiao Bai, Haonan Luo, Zechao Li, Joey Tianyi Zhou",
        content : "Embodied Artificial Intelligence has emerged as an essential area for research and applications. Unlike traditional AI systems that operate solely in the digital realm, Embodied AI seeks to imbue machines with a physical presence, enabling them to …",
        date: "30 April 2025"

    },
    {
        id:2,
        title:"Advances in Computer Vision Theory and Applications",
        link:"https://www.sciencedirect.com/journal/image-and-vision-computing/about/call-for-papers#advances-in-computer-vision-theory-and-applications",
        author: "Petia Radeva, Antonino Furnari - Submission deadline: 10 July 2024",
        content : "The last decade has seen a revolution in the theory and application of Artificial Intelligence and Machine Learning in Computer Vision. In this conference edition, we look at advances in Computer Vision based on Machine Learning methods.",
        date: "10 July 2024"

    },
    {
        id:3,
        title:"Recent Advances in Computer Vision for Assisted Living",
        link:"https://www.sciencedirect.com/journal/image-and-vision-computing/about/call-for-papers#recent-advances-in-computer-vision-for-assisted-living",
        author: "Imad Rida, Francisco Florez-Revuelta",
        content : "The percentage of persons who require healthcare support and a secure environment is unquestionably rising in our aging culture. As a result, there is an increasing need for technological solutions for prevention, diagnosis, and rehabilitation. …",
        date: "15 August 2024"

    }
]

export default Aiopensec7;