import React from 'react'

const Imagevisionsec9 = [
    {
        id:1,
        title:"Recent Advances in Computer Vision for Assisted Living",
        editor: "Dr. Imad Rida, Professor Francisco Florez-Revuelta",
        date: "19 March 2024",
        link:"https://www.sciencedirect.com/journal/image-and-vision-computing/special-issue/10JQ7QFVWLN"
    },{
        id:2,
        title:"Human-oriented 3D vision in the Metaverse",
        editor: "Xin Ning, Prayag Tiwari, Xiao Bai, Lusi Li, Xiaojie Guo",
        date: "14 December 2023",
        link:"https://www.sciencedirect.com/journal/image-and-vision-computing/special-issue/10JQ7QFVWLN"
    },
    {
        id:3,
        title:"AI on Digital Health: Computer vision applications in medical imaging",
        editor: "Paola Barra, Alessia Auriemma Citarella, Thierry BOUWMANS, Giuseppe Placidi",
        date: "7 November 2023",
        link:"https://www.sciencedirect.com/journal/image-and-vision-computing/special-issue/10JQ7QFVWLN"
    },{
        id:4,
        title:"Synthetic Data in Generalizable Video Analytics",
        editor: "Xun Yang, Liang Zheng, Piotr Koniusz, Milind Naphade, Meng Wang",
        date: "5 April 2023",
        link:"https://www.sciencedirect.com/journal/image-and-vision-computing/special-issue/10JQ7QFVWLN"
    }
]


export default Imagevisionsec9;