import React from 'react'
import "./Articlesec3.css"
import Articlebutton from '../../Articlebutton/Articlebutton'

// props :
// articlePublishRate
// firstDecision
// reviewDate
// submissionAcceptance

const Articlesec3 = (props) => {
  return (
    <div className='Articlesec3 h-full w-full py-8 max-sm:py-2'>
        <div className="Articledetails h-full w-4/5 mx-auto grid grid-cols-5 
        max-lg:w-11/12 max-sm:grid-cols-1 max-sm:gap-y-4 max-md:grid-cols-2 max-md:gap-4">
            
            <div className='h-fit py-2 px-4 border-l-2 border-slate-900 flex flex-col gap-2'>
                <h1 className='text-4xl text-slate-900 font-semibold max-sm:text-3xl'>${props.articlePublishRate}</h1>
                <p className='text-lg max-sm:text-base'>Article publishing charge for open access</p>
            </div>
            
            <div className='h-fit py-2 px-4 border-l-2 border-slate-900 flex flex-col gap-2'>
                <h1 className='text-4xl text-slate-900 font-semibold max-sm:text-3xl'>{props.firstDecision} days</h1>
                <p className='text-lg max-sm:text-base'>Time to first decision</p>
            </div>
            
            <div className='h-fit py-2 px-4 border-l-2 border-slate-900 flex flex-col gap-2'>
                <h1 className='text-4xl text-slate-900 font-semibold max-sm:text-3xl'>{props.reviewDate}</h1>
                <p className='text-lg max-sm:text-base'>Review time</p>
            </div>
            
            <div className='h-fit py-2 px-4 border-l-2 border-slate-900 flex flex-col gap-2'>
                <h1 className='text-4xl text-slate-900 font-semibold max-sm:text-3xl'>{props.submissionAcceptance}</h1>
                <p className='text-lg max-sm:text-base'>Submission to acceptance</p>
            </div>

            <div className='h-fit py-2 px-4 border-l-2 border-slate-900 flex items-center gap-2'>
                <Articlebutton name="View all Insights" link={props.buttonLink} />
            </div>
        </div>
    </div>
  )
}

export default Articlesec3;