import React from 'react'
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import Home from '../Pages/Home';
import Header from '../Components/Header/Header';
import Footer from "../Components/Footer/Footer"
import Imagevision from '../Pages/Imagevision';
import Aiopen from '../Pages/Aiopen';

const Routing = () => {
  
  return (
    <div className='h-full overflow-hidden'>    
        <Router >
          <Header />
          <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path='/imagevision' element={<Imagevision />}/>
              <Route path='/aiopen' element={<Aiopen />}/>
          </Routes>
          <Footer />
        </Router>
    </div>
  )
}

export default Routing;