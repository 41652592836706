import React from 'react'

const Imgvisionsec10 = () => {
  return (
    <div className='Imgvisionsec10 h-full w-full py-6 mt-12 bg-[#f5f5f5]'>
        <div className="Imgvisionsec10Container h-full w-4/5 mx-auto max-md:w-11/12">
            
            <div className="Imgvisionsec10Copyright h-full w-full py-12 flex gap-6 max-md:flex-col max-md:py-4">
                
                <div className="Imgvisionsec10Left flex flex-col border-r-2 py-4 pr-10 max-md:py-1">
                    <p className='text-xl'>Online ISSN: 1872-8138</p>
                    <p className='text-xl'>Print ISSN: 0262-8856</p>
                </div>
                
                <div className="Imgvisionsec10Right h-full w-1/4 py-4 ml-6 max-md:ml-0 max-md:w-3/4 max-md:py-1">
                    <p className='text-xl'>Copyright © 2024 Elsevier B.V. All rights are reserved, including those for text and data mining, AI training, and similar technologies.</p>
                </div>
            
            </div>

            <div className="Imgvisionsec10LinkBlock h-full w-full py-4 grid grid-cols-3 gap-8 max-md:grid-cols-1">
                {/* link 1 */}
                <div className='Imgvisionsec10Block'>
                    <div className="Imgvisionsec10LinkHead">
                        <h1 className='text-xl py-4 border-b border-slate-950'>For authors</h1>
                    </div>
                    <div className="Imgvisionsec10Link flex flex-col gap-4 py-4">
                        <a className='text-xl text-blue-700 w-fit border-b-2 border-transparent hover:border-orange max-md:text-base' 
                        href="https://www.elsevier.com/researcher/author">Resources for authors <i class="ri-arrow-right-up-line"></i></a>
                        <a className='text-xl text-blue-700 w-fit border-b-2 border-transparent hover:border-orange max-md:text-base' 
                        href="https://authors.elsevier.com/tracking/landingpage/selection.do">Track your accepted paper <i class="ri-arrow-right-up-line"></i></a>
                        <a className='text-xl text-blue-700 w-fit border-b-2 border-transparent hover:border-orange max-md:text-base' 
                        href="https://journalfinder.elsevier.com/">Journal Finder <i class="ri-arrow-right-up-line"></i></a>
                        <a className='text-xl text-blue-700 w-fit border-b-2 border-transparent hover:border-orange max-md:text-base' 
                        href="https://researcheracademy.elsevier.com/">Researcher Academy <i class="ri-arrow-right-up-line"></i></a>
                        <a className='text-xl text-blue-700 w-fit border-b-2 border-transparent hover:border-orange max-md:text-base' 
                        href="https://www.elsevier.com/about/policies-and-standards/copyright/permissions">Rights and permissions <i class="ri-arrow-right-up-line"></i></a>
                        <a className='text-xl text-blue-700 w-fit border-b-2 border-transparent hover:border-orange max-md:text-base' 
                        href="https://service.elsevier.com/app/home/supporthub/publishing/#authors">Journal Article Publishing Support Center <i class="ri-arrow-right-up-line"></i></a>
                    </div>
                </div>
                {/* link 2 */}
                <div className='Imgvisionsec10Block'>
                    <div className="Imgvisionsec10LinkHead">
                        <h1 className='text-xl py-4 border-b border-slate-950'>For Editors</h1>
                    </div>
                    <div className="Imgvisionsec10Link py-4 flex flex-col gap-4">
                        <a className='text-xl text-blue-700 w-fit border-b-2 border-transparent hover:border-orange max-md:text-base' 
                        href="https://www.elsevier.com/editor">Resource for editors <i class="ri-arrow-right-up-line"></i></a>
                        <a className='text-xl text-blue-700 w-fit border-b-2 border-transparent hover:border-orange max-md:text-base' 
                        href="https://www.elsevier.com/editor/perk">Publishing Ethics Rsource Kit <i class="ri-arrow-right-up-line"></i></a>
                        <a className='text-xl text-blue-700 w-fit border-b-2 border-transparent hover:border-orange max-md:text-base'
                        href="https://www.elsevier.com/editor/role/guest">Guest editors <i class="ri-arrow-right-up-line"></i></a>
                    </div>
                </div>
                {/* link 3 */}
                <div className='Imgvisionsec10Block'>
                    <div className="Imgvisionsec10LinkHead">
                        <h1 className='text-xl py-4 border-b border-slate-950'>For Reviewers</h1>
                    </div>
                    <div className="Imgvisionsec10Link py-4 flex flex-col gap-4">
                        <a className='text-xl text-blue-700 w-fit border-b-2 border-transparent hover:border-orange max-md:text-base' 
                        href="https://www.elsevier.com/reviewer">Resources for Reviewers <i class="ri-arrow-right-up-line"></i></a>
                        <a className='text-xl text-blue-700 w-fit border-b-2 border-transparent hover:border-orange max-md:text-base' 
                        href="https://www.elsevier.com/reviewer/role#3-recognizing-reviewers">Reviewers recognition <i class="ri-arrow-right-up-line"></i></a>
                    </div>
                </div>
            
            </div>
        
        </div>
    </div>
  )
}

export default Imgvisionsec10;