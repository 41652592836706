import React from 'react'


// props:
// articleCover-img
// articleName - str
// siteScore 
// impactFactor

const Articlesec1 = (props) => {

  return (
    <div className='ArticleSec1 relative h-full w-full'>
        <div className="ArticleCoverImg h-20 w-full">
        </div>
        <div className="ArticleTitle h-[230px] w-full bg-[#505050]">
            <div className="ArticleTitleContainer h-full w-4/5 mx-auto flex items-center justify-between max-lg:w-11/12">
                <div className="ArticleContainerLeft">
                    <img className='h-fit w-[280px] object-contain shadow-2xl -translate-y-12 max-md:translate-y-0' src={props.articleCover} alt="" />
                </div>
                <div className="ArticleContainerMiddle px-10 flex flex-col gap-2 w-full max-md:px-4">
                    <h1 className='text-4xl text-white tracking-wide max-md:text-2xl'>{props.articleName}</h1>
                    <a className='text-white text-xl max-md:text-sm' href="#">Support open access</a>
                </div>
                <div className="ArticleContainerRight h-[70px] w-fit flex gap-4 items-center max-md:justify-center max-md:flex-col max-md:h-full">
                    <div className='text-white font-semibold text-center'>
                      <h1 className='text-3xl max-md:text-xl'>{props.siteScore}</h1>
                      <p className='text-lg tracking-wide max-md:text-sm'>CiteScore</p>
                    </div>

                    <div className='h-full w-[1px] bg-white max-md:hidden'></div>
                    
                    <div className='w-fit text-white font-semibold text-center'>
                      <h1 className='text-3xl max-md:text-xl'>{props.impactFactor}</h1>
                      <p className='text-lg tracking-wide max-md:text-sm'>ImpactFactor</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Articlesec1;