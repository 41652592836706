import React from 'react'
import { useState } from 'react'
import Articlebutton from '../../Articlebutton/Articlebutton'
import News from './News'
import Announcement from './Announcement'
import Callforpaper from './Callforpaper'

const Imgvisionsec6 = (props) => {
  const newsData = props.newsData
  const announcementData = props.announcementData
  const callforpaperData = props.callforpaperData
  const [blockno, setblockno] = useState(1)
  function blockno1(){
    setblockno(1)
  }
  function blockno2(){
    setblockno(2)
  }
  function blockno3(){
    setblockno(3)
  }
  
  return (
    <div className='Imgvisionsec6 h-full w-full py-8' >
        <div className="Imgvisionsec6Container h-full w-4/5 mx-auto max-md:w-11/12">
          
          <div className="Imgvisionsec6Head">
            <div className='h-2 w-12 bg-slate-900 my-1'></div>
            <h1 className='text-3xl pb-8 font-semibold tracking-normal max-md:text-2xl max-sm:pb-2'>More from Image and Vision Computing</h1>
          </div>

          <div className="Imgvisionsec6Link h-full w-full border-b border-slate-950 flex">
              <button onClick={blockno1} className={blockno === 1 ? 'text-2xl px-6 py-2 border-b-4 border-slate-900 hover:border-b-4 hover:border-slate-900 transition-all max-md:text-xl max-md:px-4 max-md:pb-0' :
              'text-2xl px-6 py-2 border-b-4 border-transparent hover:border-b-4 hover:border-slate-900 transition-all max-md:text-xl max-md:px-4 max-md:pb-0'}>News</button>
              <button onClick={blockno2} className={blockno === 2 ? 'text-2xl px-6 py-2 border-b-4 border-slate-950 hover:border-b-4 hover:border-slate-900 transition-all max-md:text-xl max-md:px-4 max-md:pb-0' :
              'text-2xl px-6 py-2 border-b-4 border-transparent hover:border-b-4 hover:border-slate-900 transition-all max-md:text-xl max-md:px-4 max-md:pb-0'}>Announcements</button>
              <button onClick={blockno3} className={blockno === 3 ? 'text-2xl px-6 py-2 border-b-4 border-slate-950 hover:border-b-4 hover:border-slate-900 transition-all max-md:text-xl max-md:px-4 max-md:pb-0' : 
              'text-2xl px-6 py-2 border-b-4 border-transparent hover:border-b-4 hover:border-slate-900 transition-all max-md:text-xl max-md:px-4 max-md:pb-0'}>Call for Papers</button>
          </div>
          {
            blockno === 1 && <News data={newsData} />
          }

          {
            blockno === 2 && <Announcement announcementData={announcementData} />
          }

          {
            blockno === 3 && <Callforpaper  callforpaperData={callforpaperData} />
          }

          <div className="Imgvisionsec6Button h-full w-full py-6">
              <Articlebutton link={props.link} name="View all news" />
          </div>
          
        </div>
    </div>
  )
}

export default Imgvisionsec6;